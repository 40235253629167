/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import * as FaIcons from "react-icons/fa6";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import '../../SCSS/popups.scss';
import AccordionNote from '../../commonModules/UI/AccordianNote';
import { IoCopy } from "react-icons/io5";
import { FiExternalLink } from 'react-icons/fi';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoChevronBackSharp } from "react-icons/io5";
import CreateNoteModal from '../../commonModules/UI/CreateNoteModal';
import { useCompanyIndustry, useCompanyType, useCompanyOwners } from '../../hooks/company';
import { checkHubSpotAccess, deleteHSCompany, deleteHSNotes, getSingleHSCompany, updateHSCompany } from '../../../API/authCurd';
import { Tooltip, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { hsCompanyUpdateSchema } from '../../../Utils/validation';
import { ErrorMessage, PermissionMessage, useHsNotes, useSetPrimary } from '../../hooks/hubspot';
import { amountCheck, checkPermission, cleanAnnualRevenue, constructHubSpotFileUrl, deletePropertiesIfExists, extractIds, formatCurrency, getNameInitials, urlRedirect } from '../../../Utils/helpers';
import { useUploadPhotoModal } from '../../hooks/uploadPhoto';
import EditableAvatar from '../../commonModules/UI/EditableAvatar';
import { FaRegEdit } from "react-icons/fa";
import EditCompanyTooltip from '../../commonModules/UI/EditCompanyTooltip';
import SearchDropdown from '../../commonModules/UI/SearchDropdown';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import { useDeleteHs } from '../../hooks/useDeleteHs';
import ContactAssociation from '../../commonModules/UI/associationForms/contact/ContactAssociation';
import CompanyAssociation from '../../commonModules/UI/associationForms/company/CompanyAssociation';
import DealAssociation from '../../commonModules/UI/associationForms/deals/DealAssociation';
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import { ContactOffCanvas } from '../../commonModules/UI/PreviewButton';
import { CustomHSDeleteDealsPrimary, CustomHSDeletePrimary } from '../../commonModules/UI/Dialogue';
import { CompanyOffCanvas } from '../../commonModules/UI/PreviewButtonCompany';
import { DealsOffCanvas } from '../../commonModules/UI/PreviewButtonDeal';

const initialValues = {
  domain: '',
  hubspot_owner_id: '',
  industry: '',
  type: '',
  city: '',
  state: '',
  zip: '',
  numberofemployees: '',
  annualrevenue: '',
  timezone: '',
  description: '',
  linkedin_company_page: '',
  hs_object_source_label: '',
}

const HubspotCompanyDetails = () => {
  const { id } = useParams();
  const [openCreateNote, setOpenCreateNote] = useState(false);
  const [isSaving, setIsSaving] = useState(false)
  const { confirmDeleteWindow, DeleteHSConfirmModal } = useDeleteHs();
  const [copyText, setCopyText] = useState("Copy domain to clipboard")
  const [copyTextPhone, setCopyTextPhone] = useState("Copy phone to clipboard")
  const { companyOwners, getCompanyOwners } = useCompanyOwners();
  const { companyType, getCompanyType } = useCompanyType();
  const { companyIndustry, getCompanyIndustry } = useCompanyIndustry();
  const { notes, setNotes, getNotes, notesPaging, fetchNextNotes } = useHsNotes();
  const [companyData, setCompanyData] = useState(null)
  const navigate = useNavigate()
  const { openModal, ModalComponent } = useUploadPhotoModal();
  const [tagList, setTagList] = useState([])
  const [notesLoading, setNotesLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [hasHsAccess, setHasHsAccess] = useState(true)
  const [callApi, setCallApi] = useState(false)
  const { userData } = useContext(GlobalSearch)

  const [contactAssociations, setContactAssociations] = useState(null)
  const [companyAssociations, setCompanyAssociations] = useState(null)
  const [dealAssociations, setDealAssociations] = useState(null)
  const [showLoader, setShowLoader] = useState({ contacts: false, companies: false, deals: false })

  const { confirmSetPrimaryWindow, SetPrimaryConfirmModal } = useSetPrimary();

  const [selectedContactPreview, setSelectedContactPreview] = useState(null)
  const [showContactDrawer, setShowContactDrawer] = useState(false);

  const [showCompanyDrawer, setShowCompanyDrawer] = useState(false);
  const [selectedCompanyPreview, setSelectedCompanyPreview] = useState(null);

  const [showDealDrawer, setShowDealDrawer] = useState(false);
  const [selectedDealPreview, setSelectedDealPreview] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showModalOne, setShowModalOne] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [selectedValue, setSelectedValue] = useState("")
  const [dropdown, setDropdown] = useState([])

  useEffect(() => {
    let permission = checkPermission("HubSpot")
    if (!permission) {
      navigate("/dashboard")
    }
  }, [])

  const { errors, resetForm, getFieldProps, handleSubmit, touched, values, setFieldValue } = useFormik({
    initialValues,
    validationSchema: hsCompanyUpdateSchema,
    onSubmit: (values) => {
      let data = { ...values }
      delete data.hs_object_source_label;
      deletePropertiesIfExists(data, ["hs_object_source"]);
      if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      data.annualrevenue = cleanAnnualRevenue(data.annualrevenue || "")
      setIsSaving(true)
      updateHSCompany(id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendAlert({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
        } else {
        sendAlert({ content: 'Changes saved' })
        getSingleCompanyData(id)
        }
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  async function getSingleCompanyData(id, loader = false) {
    if (loader) {
      setCompanyLoading(true)
      setShowLoader({ contacts: true, companies: true, deals: true })
    }
    return getSingleHSCompany(id).then((res) => {
      if (res?.data?.companies?.[0]) {
        setCompanyData(res?.data?.companies?.[0])
        setCompanyAssociations(res?.data?.companies?.[0]?.associations?.associatedCompanies ? res?.data?.companies?.[0]?.associations?.associatedCompanies : null)
        setContactAssociations(res?.data?.companies?.[0]?.associations?.contacts ? res?.data?.companies?.[0]?.associations?.contacts : null)
        setDealAssociations(res?.data?.companies?.[0]?.associations?.deals ? res?.data?.companies?.[0]?.associations?.deals : null)
      }
      return res
    }).catch(() => { setCompanyData(null); return })
      .finally(() => {
        setShowLoader({ contacts: false, companies: false, deals: false })
        setCompanyLoading(false)
      })
  }

  useEffect(() => {
    if (id && callApi) {
      getSingleCompanyData(id, true)
      getCompanyOwners()
      getCompanyType()
      getCompanyIndustry()
      setNotesLoading(true)
      getNotes('company', id).finally(() => { setNotesLoading(false) })
    }
  }, [id, callApi])

  useEffect(() => {
    if (companyOwners) {
      let users = companyOwners?.map((item) => ({
        id: item.id,
        value: `${item?.domain ? item?.domain : ""}`,
      }))
      setTagList(users)
    } else {
      setTagList([])
    }
  }, [companyOwners])

  useEffect(() => {
    if (companyData) {
      Object.keys(initialValues).forEach((field) => {
        let value = companyData?.properties?.[field] ? companyData?.properties?.[field] : "";

        if (field === 'annualrevenue' && value) {
          value = formatCurrency(value);
        }

        setFieldValue(field, value);
      });
    }
  }, [companyData, setFieldValue]);

  const handleCopy = (data) => {
    if (data?.properties?.email) {
      navigator.clipboard.writeText(data.properties.email).then(() => {
        setCopyText('Copied');
        setTimeout(() => {
          setCopyText('Copy email to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };

  const getName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : '--'
  }

  // delete button handler
  const deleteCompanyHandler = async (e, data) => {
    let msg = `You're about to delete the company ${data?.properties?.name ? data?.properties?.name : ""}. Once deleted, this company can be restored for up to 90 days.`
    let heading = 'Delete company?'
    let isConfirm = await confirmDeleteWindow(msg, heading)
    if (isConfirm) {
      if (data) {
        deleteHSCompany(data?.id).then(() => {
          navigate("/hubspot/companies")
        }).catch((err) => { })
      }
    }
  }

  const onCreateNoteButtonClick = () => {
    setOpenCreateNote(true)
  }

  const onCreateNoteCancelButtonClick = (load = false, item) => {
    if (item?.data?.notes) {
      setNotes((prev) => [item?.data?.notes, ...prev])
    }
    // if (load) {
    //   getNotes('company', id);
    // }
    setOpenCreateNote(false)
  }

  const fetchNotes = (load = false) => {
    if (load) {
      getNotes('company', id);
    }
    setOpenCreateNote(false)
  }

  const fetchNext = async (afterId) => {
    if (afterId) {
      return fetchNextNotes(afterId, 'company', id)
    }
  }

  const deleteNoteButtonHandler = async (deleteId) => {
    let msg = `Are you sure you want to delete this note?`
    let isConfirm = await confirmDeleteWindow(msg)
    if (isConfirm) {
      if (deleteId) {
        deleteHSNotes(deleteId).then(() => {
          getNotes('company', id);
        }).catch((err) => { })
      }
    }
  }

  const DomainHandleCopy = (companyData) => {
    if (companyData?.properties?.domain) {
      navigator.clipboard.writeText(companyData?.properties?.domain).then(() => {
        setCopyText('Copied');
        setTimeout(() => {
          setCopyText('Copy domain to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };
  const PhoneHandleCopy = (companyData) => {
    if (companyData?.properties?.phone) {
      navigator.clipboard.writeText(companyData?.properties?.phone).then(() => {
        setCopyTextPhone('Copied');
        setTimeout(() => {
          setCopyTextPhone('Copy phone to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };
  const handlePhotoUpload = (file) => { };

  const openPhotoUploadModal = (record) => {
    let initials = getNameInitials(record?.properties?.firstname, record?.properties?.lastname)
    openModal({ initials })
  }

  const openFileRemoveHandler = (record) => { }

  const handleUpload = () => {
    openPhotoUploadModal(companyData)
  };

  const handleRemove = () => {
    openFileRemoveHandler(companyData)
  };

  const onUpdateHandler = (load) => {
    if (load) {
      return getSingleCompanyData(id)
    }
  }

  const sendAlert = ({ type, content, className }) => {
    if (content) {
      messageApi.open({
        type: type || 'success',
        content: content,
        className: `alert-message ${className ? className : ''}`,
      });
    }
  }

  const getContactName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : data?.properties?.email
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const contactAssociationRemove = async (e, data) => {
    let msg = `${getContactName(data)} will no longer be associated with ${getNameOrDomain(companyData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const companyAssociationRemove = async (e, data) => {
    let msg = `${getNameOrDomain(data)} will no longer be associated with ${getNameOrDomain(companyData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const getDealName = (data) => {
    let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
    return name ? name : '--'
  }

  const dealAssociationRemove = async (e, data) => {
    let msg = `${getDealName(data)} will no longer be associated with ${getNameOrDomain(companyData)}`
    return await removeHandler(msg, 'Remove association')
  }


  const removeHandler = async (msg, heading) => {
    return await confirmDeleteWindow(msg, heading)
  }

  const getNameForDeal = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : ''
  }

  useEffect(() => {
    if (userData?.email) {
      let payload = {
        email: userData?.email
      }
      checkHubSpotAccess(payload).then((res) => {
        setHasHsAccess(true)
        setCallApi(true)
      }).catch((err) => {
        setHasHsAccess(false)
        setNotesLoading(false)
        setCompanyLoading(false)
      })
    }
  }, [userData])

  const onContactPreviewClick = (item) => {
    if (item) {
      setSelectedContactPreview(item)
      setShowContactDrawer(true)
      setTimeout(() => {
        setShowCompanyDrawer(false)
        setSelectedCompanyPreview(null)
        setSelectedDealPreview(false)
        setShowDealDrawer(false)
      }, 250)
    }
  }

  const onContactPreviewClose = () => {
    setSelectedContactPreview(null)
  }

  const handleSetPrimary = async (e, data, pk, type, section, associate_to, contactData) => {
    let heading = `Set ${getNameOrDomain(data)} as primary`
    let msg = `Replace ${contactData?.properties?.associatedcompanyid ? contactData?.properties?.primary_company_name : ''} as primary`
    return await confirmSetPrimaryWindow(msg, heading)
  }

  const handlePrimaryAssocoationRemove = async (msg, heading, primaryKey, dropDown, subHeading) => {
    let dropdownList = dropDown ? dropDown : []
    return new Promise((resolve) => {
      setSelectedValue("")
      setDropdown(dropdownList)
      setModalMessage({ msg, heading, subHeading });
      setConfirmCallback(() => resolve);
      setShowModal(true);
    });
  }

  const onCompanyPreviewClick = (item) => {
    if (item) {
      setSelectedCompanyPreview(item)
      setShowCompanyDrawer(true)
      setTimeout(() => {
        setShowContactDrawer(false)
        setSelectedContactPreview(null)
        setSelectedDealPreview(null)
        setShowDealDrawer(false)
      }, 250)
    }
  }

  const onCompanyPreviewClose = () => {
    getSingleCompanyData(id)
    setSelectedCompanyPreview(null)
  }

  const onDealPreviewClick = (item) => {
    setSelectedDealPreview(item)
    setShowDealDrawer(true)
    setTimeout(() => {
      setShowContactDrawer(false)
      setSelectedContactPreview(null)
      setShowCompanyDrawer(false)
      setSelectedCompanyPreview(null)
    }, 250)
  }

  const onDealPreviewClose = () => {
    getSingleCompanyData(id)
    setSelectedDealPreview(null)
  }

  const handleDealPrimaryAssociationRemove = async (msg, heading, primaryKey, dropDown, subHeading, warning) => {
    let dropdownList = dropDown ? dropDown : []
    return new Promise((resolve) => {
        setSelectedValue("")
        setDropdown(dropdownList)
        setModalMessage({ msg, heading, subHeading, warning });
        setConfirmCallback(() => resolve);
        setShowModalOne(true);
    });
}

  return (
    <>
      {contextHolder}
      <div style={{ paddingTop: '60px', background: '#F5F8FA', width: '100%' }}>
        <div className='hubspot-section hubspot-section-details'>
          {hasHsAccess ? <div className='column'>
            <div className='personal-info-col'>
              <div className='back-arrow'>
                <IoChevronBackSharp onClick={() => { navigate("/hubspot/companies") }} />
                <NavLink to={'/hubspot/companies'}>Companies</NavLink>
              </div>
              {companyLoading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
                <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
              </div> : <><div className='personal-info-wrap'>
                <EditableAvatar
                  initials={getNameInitials(companyData?.properties?.name, companyData?.properties?.name)}
                  onUpload={handleUpload}
                  onRemove={handleRemove}
                  imgUrl={companyData?.properties?.hs_avatar_filemanager_key ? constructHubSpotFileUrl(companyData?.properties?.hs_avatar_filemanager_key) : companyData?.properties?.hs_logo_url}
                />

                <div className='edit-details-wrap'>
                  <div className='personal-info'>
                    <h6>
                      {companyData?.properties?.name ? companyData?.properties?.name : '--'}
                    </h6>
                    {companyData?.properties?.domain && <p>
                      <a
                        href={`https://${companyData?.properties?.domain}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {companyData?.properties?.domain}
                      </a>
                      <Tooltip placement="top" title={'domain'}>
                        <FiExternalLink className={`ms-2`} onClick={() => urlRedirect(companyData?.properties?.domain)} />
                      </Tooltip>
                      <Tooltip placement="top" title={copyText}>
                        <IoCopy className={`ms-2`} onClick={() => DomainHandleCopy(companyData)} />
                      </Tooltip>
                    </p>}
                    <p>
                      {companyData?.properties?.phone}
                      {companyData?.properties?.phone && (
                        <Tooltip placement="top" title={copyTextPhone}>
                          <IoCopy className="ms-2" onClick={() => PhoneHandleCopy(companyData)} />
                        </Tooltip>
                      )}
                    </p>

                  </div>
                  <EditCompanyTooltip record={companyData} id={id} onUpdate={onUpdateHandler} sendMessage={sendAlert} />
                </div>
              </div>
                <div className='main-btn-wrap'>
                  <div className='delete-btn button-wrap' onClick={onCreateNoteButtonClick}>
                    <div className='icon'> <FaRegEdit /></div>
                    <p>Note</p>
                  </div>
                  <div className='delete-btn button-wrap' onClick={(e) => deleteCompanyHandler(e, companyData)}>
                    <div className='icon'> <RiDeleteBin6Line /></div>
                    <p>Delete</p>
                  </div>
                </div></>}
              <div className='form-wrap'>
                <h5>About this company</h5>
                <div>
                  <FloatingLabel label="Company Domain Name">
                    <Form.Control
                      type="text"
                      name='domain'
                      {...getFieldProps("domain")}
                    />
                  </FloatingLabel>
                  {(touched.domain && errors.domain) ? <span className='ms-2 text-danger'>{errors.domain}</span> : null}
                </div>
                <SearchDropdown
                  label="Industry"
                  options={[
                    { label: "Select an industry", value: "" },
                    ...sortObjectsByAttribute(companyIndustry, 'displayOrder')
                  ]}
                  // placeholder="Choose..."
                  placeholder="Select an industry"
                  onSelect={(value) => setFieldValue('industry', value)}
                  initialValue={values?.industry ?? "default"}
                />
                <SearchDropdown
                  label="Company Owner"
                  options={[
                    { label: "No Owner", value: 'default' },
                    ...sortByConcatenatedString(companyOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                      value: id,
                      label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
                    }))]}
                  // placeholder="Choose..."
                  placeholder="Select an owner"
                  onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
                  initialValue={values?.hubspot_owner_id ?? "default"}
                />
                <SearchDropdown
                  label="Type"
                  options={[
                    { label: "Select a type", value: "" },
                    ...sortObjectsByAttribute(companyType, 'displayOrder')
                  ]}
                  // placeholder="Choose..."
                  placeholder="Select a type"
                  onSelect={(value) => setFieldValue('type', value)}
                  initialValue={values?.type ?? "default"}
                />
                <div>
                  <FloatingLabel label='City' className='custom-select'>
                    <Form.Control
                      type="text"
                      value={companyData?.properties?.city}
                      name='city'
                      {...getFieldProps("city")}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='State/Region' className='custom-select'>
                    <Form.Control
                      type="text"
                      value={companyData?.properties?.state}
                      name='state'
                      {...getFieldProps("state")}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='Postal Code' className='custom-select'>
                    <Form.Control
                      type="text"
                      value
                      name='zip'
                      {...getFieldProps("zip")}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='Number Of Employees' className='custom-select'>
                    <Form.Control
                      type="text"
                      value
                      name='numberofemployees'
                      {...getFieldProps("numberofemployees")}
                      disabled={false}
                      onKeyDown={(e) => amountCheck(e)}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='Annual Revenue' className='custom-select'>
                    <Form.Control
                      type="text"
                      value={values?.annualrevenue}
                      name='annualrevenue'
                      {...getFieldProps("annualrevenue")}
                      onKeyDown={(e) => amountCheck(e)}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='Time Zone' className='custom-select'>
                    <Form.Control
                      type="text"
                      value
                      name='timezone'
                      {...getFieldProps("timezone")}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='Description' className='custom-select'>
                    <Form.Control
                      type="text"
                      value
                      name='description'
                      {...getFieldProps("description")}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='LinkedIn Company Page' className='custom-select'>
                    <Form.Control
                      type="text"
                      value
                      name='linkedin_company_page'
                      {...getFieldProps("linkedin_company_page")}
                      disabled={false}
                    />
                  </FloatingLabel>
                </div>

                <div>
                  <FloatingLabel label='Record Source' className='custom-select'>
                    <Form.Control
                      type="text"
                      value
                      name='hs_object_source_label'
                      {...getFieldProps("hs_object_source_label")}
                      disabled={true}
                    />
                  </FloatingLabel>
                </div>

                <div className='d-flex align-items-center justify-content-center'>
                  {/* <Button className="mx-4 cclBtn" >Cancel</Button> */}
                  <Button disabled={isSaving} type="button" onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                </div>
              </div>
            </div>
            <div className='note-overview-col'>
              <div className='note-header-wrap'>
                <h5>Overview</h5>
                <div className='note-head'>
                  <p>Notes</p>
                  <Button className="headBtn" onClick={onCreateNoteButtonClick}> <FaIcons.FaPlus />Create Note</Button>
                </div>
                {/* <p className='note-month'>August 2024</p> */}
              </div>
              <AccordionNote loading={notesLoading} tagList={tagList} items={notes} owners={companyOwners} paging={notesPaging} fetchNext={fetchNext} fetchNotes={fetchNotes} onDelete={deleteNoteButtonHandler} type={'contact'} associatedObjectId={id} />
            </div>
            <div className='association-overview-col'>
              <div className='association-wrapper'>
                <ContactAssociation
                  id={companyData?.id}
                  section={'companies'}
                  type={'company_to_contact'}
                  associate_to={'contacts'}
                  associatedContacts={contactAssociations?.results ? contactAssociations?.results : []}
                  associatedContactIds={extractIds(contactAssociations?.results ? contactAssociations?.results : [])}
                  fetchData={getSingleCompanyData}
                  sendMessage={sendAlert}
                  handleRemove={contactAssociationRemove}
                  showLoader={showLoader}
                  setShowLoader={setShowLoader}
                  onContactPreviewClick={onContactPreviewClick}
                />
                <div className='border-bottom'></div>
                <CompanyAssociation
                  id={companyData?.id}
                  section={'companies'}
                  type={'company_to_company'}
                  associate_to={'companies'}
                  associatedCompanies={companyAssociations ? companyAssociations : []}
                  associatedCompanyIds={extractIds(companyAssociations ? companyAssociations : [])}
                  fetchData={getSingleCompanyData}
                  sendMessage={sendAlert}
                  handleRemove={companyAssociationRemove}
                  showLoader={showLoader}
                  setShowLoader={setShowLoader}
                  onCompanyPreviewClick={onCompanyPreviewClick}
                />
                <div className='border-bottom'></div>
                <DealAssociation
                  id={companyData?.id}
                  section={'companies'}
                  type={'company_to_deal'}
                  associate_to={'deals'}
                  associatedDeals={dealAssociations ? dealAssociations : []}
                  associatedDealIds={extractIds(dealAssociations ? dealAssociations : [])}
                  fetchData={getSingleCompanyData}
                  sendMessage={sendAlert}
                  handleRemove={dealAssociationRemove}
                  name={`${getNameForDeal(companyData)}`.trim()}
                  showLoader={showLoader}
                  setShowLoader={setShowLoader}
                  onDealPreviewClick={onDealPreviewClick}
                />
              </div>
            </div>
          </div> : <PermissionMessage />}
        </div>
      </div>
      {hasHsAccess && <>
        {openCreateNote && <CreateNoteModal tagList={tagList} onCancel={onCreateNoteCancelButtonClick} type={'company'} associatedObjectId={id} owners={companyOwners} />}
        {<DeleteHSConfirmModal />}
        {<CustomHSDeletePrimary
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          show={showModal}
          message={modalMessage}
          onConfirm={() => {
            confirmCallback({ confirmed: true, value: selectedValue })
            setShowModal(false);
            setModalMessage({})
          }}
          onCancel={() => {
            confirmCallback({ confirmed: false, value: null })
            setShowModal(false);
            setModalMessage({})
          }}
          dropdown={dropdown}
        />}
        {<SetPrimaryConfirmModal />}
        <ModalComponent onConfirm={handlePhotoUpload} />
        {showCompanyDrawer && <CompanyOffCanvas
          record={selectedCompanyPreview}
          show={showCompanyDrawer}
          setShow={setShowCompanyDrawer}
          afterDelete={() => { }}
          onClose={onCompanyPreviewClose}
          viewRecord={(e, item) => {
            if (item?.id) {
              e.preventDefault()
              navigate(`/hubspot/companies/${item.id}`)
            }
          }}
          openFileUpload={() => { }}
          openFileRemove={() => { }}
          sendMessage={sendAlert}
          removeContactAssociation={removeHandler}
          onContactPreviewClick={onContactPreviewClick}
          onCompanyPreviewClick={onCompanyPreviewClick}
          onDealPreviewClick={onDealPreviewClick}
        />}
        {showContactDrawer && <ContactOffCanvas
          record={selectedContactPreview}
          show={showContactDrawer}
          setShow={setShowContactDrawer}
          afterDelete={() => { }}
          onClose={onContactPreviewClose}
          viewRecord={(e, item) => {
            e.preventDefault()
            if (item) {
              navigate(`/hubspot/contacts/${item.id}`)
            }
          }}
          openFileUpload={() => { }}
          openFileRemove={() => { }}
          createNote={() => { }}
          sendMessage={sendAlert}
          removeContactAssociation={removeHandler}
          handleSetPrimary={handleSetPrimary}
          removePrimaryAssociation={handlePrimaryAssocoationRemove}
          onContactPreviewClick={onContactPreviewClick}
          onCompanyPreviewClick={onCompanyPreviewClick}
          onDealPreviewClick={onDealPreviewClick}
        />}
        {showDealDrawer && <DealsOffCanvas
          record={selectedDealPreview}
          show={showDealDrawer}
          setShow={setShowDealDrawer}
          onClose={onDealPreviewClose}
          viewRecord={(e, item) => {
            e.preventDefault()
            if (item.id) {
              navigate(`/hubspot/deals/${item.id}`)
            }
          }}
          sendMessage={sendAlert}
          removeContactAssociation={removeHandler}
          handleSetPrimary={handleSetPrimary}
          removePrimaryAssociation={handleDealPrimaryAssociationRemove}
          onContactPreviewClick={onContactPreviewClick}
          onCompanyPreviewClick={onCompanyPreviewClick}
          onDealPreviewClick={onDealPreviewClick}
        />}
        {<CustomHSDeleteDealsPrimary
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          show={showModalOne}
          message={modalMessage}
          onConfirm={() => {
            confirmCallback({ confirmed: true, value: selectedValue })
            setShowModalOne(false);
            setModalMessage({})
          }}
          onCancel={() => {
            confirmCallback({ confirmed: false, value: null })
            setShowModalOne(false);
            setModalMessage({})
          }}
          dropdown={dropdown}
        />}
      </>}
    </>
  )
}

export default HubspotCompanyDetails